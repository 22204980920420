export const STATUS_INACTIVE = 0
export const STATUS_ACTIVE = 1

export const ORDER_TYPE_INSTANCE = 1
export const ORDER_TYPE_VOLUME = 2
export const ORDER_TYPE_SNAPSHOT = 3
export const ORDER_TYPE_IP = 4
export const ORDER_RESIZE_SERVER = 5
export const ORDER_RESIZE_VOLUME = 6
export const ORDER_TYPE_FLOATING_IP = 7

export const WALLET_LOG_CREATE = 0
export const WALLET_LOG_UP = 1
export const WALLET_LOG_DOWN = 2

export const STATUS_PENDING = 0
export const STATUS_ACCEPT = 1
export const STATUS_CANCEL = 2

export const SERVER_ACTIVE = "ACTIVE"
export const SERVER_SHUTOFF = "SHUTOFF"
export const SERVER_UNPAUSE = "UNPAUSE"
export const SERVER_PAUSED = "PAUSED"
export const SERVER_ERROR = 'ERROR'

export const VOLUME_ACTIVE = "in-use"
export const VOLUME_ERROR = "error"
export const VOLUME_AVAILABLE = "available"

export const SNAPSHOT_ACTIVE = "available"

export const BOOT_TYPE_IMAGE    = "image"
export const BOOT_TYPE_SNAPSHOT = "snapshot"

export const DISK_TYPE_SSD = "__DEFAULT__"
export const STORAGE_UNIT = "GB"

export const TYPE_CPU = 1
export const TYPE_CPU_CALCULUS = 8
export const TYPE_CPU_GRAPHIC = 9

export const PATTERN_NAME = '^([a-zA-Z0-9_-])*$'
export const PATTERN_NAME_ACCOUNT = '^[a-zA-Z]+(([\',. -][a-zA-Z ])?[a-zA-Z]*)*$'
export const PATTERN_PHONENUMBER = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'