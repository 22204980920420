<template>
	<div>
		<template v-if="!fetchLoading">
			<a-row :gutter="24">
				<a-col v-for="(row, interval) in rows" :lg="6" :span="12">
					<a-card :bordered="false" class="widget-2 h-full mb-15 select-os">
						<div class="ant-statistic" :class="{active: value.interval === interval}" @click="selectTerm(interval)">
							<div class="ant-statistic-title">
								<h6>{{ row.label }}</h6>
							</div>
							<div class="ant-statistic-content">
								<span class="ant-statistic-content-value">
									<span class="ant-statistic-content-value-int">{{ currency(row.total_money) }}</span>
									<span class="ant-statistic-content-suffix">VNĐ</span>
								</span>
							</div>
						</div>
					</a-card>
				</a-col>
			</a-row>
		</template>
		<a-spin v-else tip="Loading..."></a-spin>
	</div>
</template>

<script>
	import OrderService from '@/services/order'
	import {currency} from '@/helper'
	export default ({
		props: {
			value: {
				type: Object,
				default: ()=> {return {'interval': ''}},
			},
			params: {
				type: Object,
				default: ()=> {return {}},
			},
			type: {
				type: Number,
				default: 0,
			}
		},
		data() {
			return {
				currency,
				fetchLoading: false,
				rows: {},
				func_delay: null
			}
		},
		watch: {
			params : function () {
				if (this.func_delay !== null)
					clearTimeout(this.func_delay)
				this.func_delay = this.getData()
			}
		},
		methods: {
			getData() {
				return setTimeout(()=> {
					this.fetchLoading = true
					OrderService.calculate(this._.merge({'type': this.type}, this.params))
					.then(res => {
						if (this._.isObject(res)) {
							this.fetchLoading = false
							this.rows = this._.get(res, 'data', {})
							if (this._.isEmpty(this.rows)) return
							this.selectTerm(this._.keys(this.rows)[0])
						}
					})
				}, 450)
			},
			selectTerm(interval) {
				this.$emit('input', this._.merge(this.rows[interval], {interval}))
			}
		}
	})

</script>