<template>
	<a-row type="flex" :gutter="24">
        <a-col :span="24" :md="17" class="mb-20">
            <a-card :bordered="false" class="mb-15">
                <template #title>
                    <h6 class="font-semibold m-0">Mua thêm Floating IP</h6>
                </template>
                <a-form :form="floatingIP" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item label="Số lượng">
                        <a-select
                            v-decorator="['qty', { rules: [{ required: true, message: 'Bạn chưa chọn số lượng!' }] }]"
                            placeholder="Số lượng"
                            @change="selectVolume"
                        >
                            <a-select-option v-for="i in 5" :value="i">{{i}}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('volume.actionCreate.form.titleSelectServer')">
                        <a-select
                            :options="servers"
                            :placeholder="$t('volume.actionCreate.form.placeholderSelectServer')"
                            v-decorator="['server_id']"
                        >
                        </a-select>
                    </a-form-item>
                    <WidgetSelectTerm
                        :params="form.config"
                        :type="form.type"
                        v-model="WidgetSelectTermData"
                    />
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button 
                            type="primary" 
                            html-type="submit"
                            :loading="fetchCreate"
                            >{{ $t('create') }}
                        </a-button>
                    </a-form-item> 
                </a-form>
            </a-card>
        </a-col>
		<a-col :span="24" :md="7" class="mb-24">
		</a-col>
	</a-row>
</template>

<script>

    import WidgetSelectTerm from "@/components/Widgets/WidgetSelectTerm"
    import OrderService from '@/services/order'
    import { notification } from 'ant-design-vue'
    import servers from "@/services/server/listing"
    import {ORDER_TYPE_FLOATING_IP} from "@/constant"

	export default ({
		components: {
            WidgetSelectTerm,
		},
		data() {
			return {
                form: {
                    type: ORDER_TYPE_FLOATING_IP,
                    config: {
                        disk: 0
                    },
                    info: {
                        server_id: ''
                    },
                    interval: ''
                },
                floatingIP: this.$form.createForm(this, { name: 'floatingIP' }),
                WidgetSelectTermData: {},
                fetchCreate: false,
                servers: [],
			}
		},
        watch: {
            WidgetSelectTermData: function (val) {
                this.form.interval = val.interval
            }
        },
        created() {
			servers()
			.then(res=> {
				if (this._.isObject(res)) {
                    this._.forEach(res, (row)=> {
                        this.servers.push({
                            label:  row.name,
                            value: row.id
                        })
                    })
				}
			})
		},
        methods : {
            selectVolume(v) {
                this.form.config = {
                    qty: v
                }
            },
            handleSubmit(e) {
                e.preventDefault();
                this.floatingIP.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    if (values.server_id !== undefined) 
                        this.form.info.server_id = values.server_id

                    let messages = []
                    
                    if (this.form.interval === '')
                        messages.push('Bạn chưa chọn kỳ hạn thanh toán')
                    
                    if (!this._.isEmpty(messages)) {
                        this._.forEach(messages, val => {
                            notification.error({
                                message: val
                            })
                        })
                        return
                    }

                    this.createOrder()
                });
            },
            createOrder() {
                this.fetchCreate = true
                OrderService.create(this.form)
                .then(res => {
                    this.fetchCreate = false
                    if (this._.isObject(res) && res.status == 1) {
                        notification.success({
                            message: 'Tạo đơn hàng thành công'
                        })
                        this.$router.push({name: 'billing.payment', params: {id: res.data.invoice_id}})
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
                })
            }
        },
        mounted() {
            this.floatingIP.setFieldsValue({
                qty: 1
            })
            this.form.config = {qty: 1}
        }
	})

</script>

<style lang="scss">
</style>